/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import EraserIcon from '@pelckmans/business-components/icons/Eraser';
import EraserFramedIcon from '@pelckmans/business-components/icons/EraserFramed';
import RulerIcon from '@pelckmans/business-components/icons/Ruler';
import SetSquareIcon from '@pelckmans/business-components/icons/SetSquare';
import DraftingCompassIcon from '@pelckmans/business-components/icons/DraftingCompass';
import DrawingTextMarkerIcon from '@pelckmans/business-components/icons/DrawingTextMarker';
import DrawingMarkerIcon from '@pelckmans/business-components/icons/DrawingMarker';
import DrawingPencilIcon from '@pelckmans/business-components/icons/DrawingPencil';
import PointerIcon from '@pelckmans/business-components/icons/Pointer';
import TextAnnotationIcon from '@pelckmans/business-components/icons/TextAnnotation';

export const TOOL_ICON_MAP = {
  pointer: props => <PointerIcon {...props} />,
  pencil: props => <DrawingPencilIcon {...props} />,
  marker: props => <DrawingMarkerIcon {...props} />,
  textmarker: props => <DrawingTextMarkerIcon {...props} />,
  annotation: props => <TextAnnotationIcon {...props} />,
  ruler: props => <RulerIcon {...props} />,
  set_square: props => <SetSquareIcon {...props} />,
  drafting_compass: props => <DraftingCompassIcon {...props} />,
  classic_eraser: props => <EraserIcon {...props} />,
  selection_eraser: props => <EraserFramedIcon {...props} />,
};
