import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import { ActionMenu, MenuItem, ActionButton } from '@pelckmans/business-components/components/action-menu';
import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import PaperType from '../../../../../../enums/paperType';
import { addWhitepage } from '../../../../../../actions/whitepages';

function AddWhitepageMenu({ tocNodeId, showOnlyFavoriteWhitepages }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const createWhitepage = (nodeId, paperType, asFavorite) => dispatch(addWhitepage(nodeId, paperType, asFavorite));

  return (
    <li className="pbb-list__item">
      <ActionMenu
        title={t('drawer.whitepages.buttons.add_tooltip')}
        direction="bottom"
        menuStyle={{ width: 340 - 2 * 16 }} // drawer width minus padding
        action={
          <span className="pbb-list__link-wrap">
            <ActionButton className="pbb-list__link">
              <PlusInCircleIcon className="pbb-list__link-icon" />
              <div className="pbb-list__link-text">{t('drawer.whitepages.buttons.add')}</div>
            </ActionButton>
          </span>
        }
      >
        {Object.values(PaperType).map(type => (
          <MenuItem key={`icon-bb-whitepage-${type}`} onClick={() => createWhitepage(tocNodeId, type, showOnlyFavoriteWhitepages)} data-testid={`add-whitepage-${type}`}>
            <MaterialIcon materialType="whitepage" paperType={type} className="pbb-list__link-icon" />
            <span className="pbb-list__link-text">{t(`drawer.whitepages.types.${type}`)}</span>
          </MenuItem>
        ))}
      </ActionMenu>
    </li>
  );
}

AddWhitepageMenu.propTypes = {
  tocNodeId: string.isRequired,
  showOnlyFavoriteWhitepages: bool.isRequired,
};

export default AddWhitepageMenu;
