/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getIsSolutionsPageVisible } from '../../../../../selectors/player';

import useSpreadSpecs from './hooks/use-spread-specs';
import useVisibleAnnotations from './hooks/use-annotations';

import TextAnnotations from '../../../../../components/text-annotations/text-annotations';
import { UserSettingsContext } from '../../../context/user-settings-context';
import { getCurrentTool } from '../../../../../selectors/tools';

export default function TextAnnotationLayer({ viewportTransform, pageWidth, pageHeight, saveTimeout }) {
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const currentTool = useSelector(getCurrentTool);
  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();
  const { showMarkings, sidebarAnchor } = useContext(UserSettingsContext);
  const [spreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation, removeAnnotation] = useVisibleAnnotations({
    saveTimeout,
    pageWidth,
    pageHeight,
  });

  if (!showMarkings) return null;

  return (
    <TextAnnotations
      viewportTransform={viewportTransform}
      pageWidth={pageWidth}
      pageHeight={pageHeight}
      annotations={spreadAnnotations}
      addAnnotation={addAnnotation}
      editAnnotation={editAnnotation}
      setSelectedAnnotationId={setSelectedAnnotationId}
      selectedAnnotation={selectedAnnotation}
      isSinglePage={isSinglePage}
      isRightPage={isRightPage}
      isStandalonePage={isStandalonePage}
      isSolutionsPageVisible={isSolutionsPageVisible}
      removeAnnotation={removeAnnotation}
      currentTool={currentTool}
      sidebarAnchor={sidebarAnchor}
    />
  );
}

TextAnnotationLayer.propTypes = {
  viewportTransform: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  saveTimeout: PropTypes.number,
};

TextAnnotationLayer.defaultProps = {
  saveTimeout: undefined,
};
