import AlignCenterIcon from '@pelckmans/business-components/icons/TextEditorAlignCenter';
import AlignJustifyIcon from '@pelckmans/business-components/icons/TextEditorAlignJustify';
import AlignLeftIcon from '@pelckmans/business-components/icons/TextEditorAlignLeft';
import AlignRightIcon from '@pelckmans/business-components/icons/TextEditorAlignRight';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import LineHeightIcon from '@pelckmans/business-components/icons/TextEditorLineHeight';
import LinkIcon from '@pelckmans/business-components/icons/TextEditorLink';
import OrderedListIcon from '@pelckmans/business-components/icons/TextEditorOrderedList';
import SpecialCharactersIcon from '@pelckmans/business-components/icons/TextEditorSpecialCharacters';
import StrikethroughIcon from '@pelckmans/business-components/icons/TextEditorStrikethrough';
import SubscriptIcon from '@pelckmans/business-components/icons/TextEditorSubscript';
import SuperscriptIcon from '@pelckmans/business-components/icons/TextEditorSuperscript';
import BoldIcon from '@pelckmans/business-components/icons/TextEditorBold';
import ItalicIcon from '@pelckmans/business-components/icons/TextEditorItalic';
import UnderlineIcon from '@pelckmans/business-components/icons/TextEditorUnderline';
import TextSizeDecreaseIcon from '@pelckmans/business-components/icons/TextEditorTextSizeDecrease';
import TextSizeIncreaseIcon from '@pelckmans/business-components/icons/TextEditorTextSizeIncrease';
import UnorderedListIcon from '@pelckmans/business-components/icons/TextEditorUnorderedList';
import FontColorIcon from '@pelckmans/business-components/icons/TextEditorFontColor';

export const ANNOTATION_PADDING = 8;
export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 19, 20, 22, 24, 26, 28, 36, 48, 52, 72, 104];
export const LINE_HEIGHTS = [1, 1.15, 1.5, 2, 2.5, 3];
export const SPECIAL_CHARACTERS = [
  '\u03B1', // α
  '\u03B2', // β
  '\u03B3', // γ
  '\u0394', // Δ
  '\u03B8', // θ
  '\u03BB', // λ
  '\u03BC', // μ
  '\u03C0', // π
  '\u03C1', // ρ
  '\u03A3', // Σ
  '\u03C6', // φ
  '\u03A6', // Φ
  '\u03A9', // Ω
  '\u2260', // ≠
  '\u2248', // ≈
  '\u003E', // >
  '\u003C', // <
  '\u2264', // ≤
  '\u2265', // ≥
  '\u0028', // (
  '\u0029', // )
  '\u005B', // [
  '\u005D', // ]
  '\u2212', // −
  '\u00B1', // ±
  '\u002A', // *
  '\u22C5', // ⋅
  '\u00F7', // ÷
  '\u221A', // √
  '\u2220', // ∠
  '\u221F', // ∟
  '\u00B0', // °
  '\u22A5', // ⊥
  '\u2225', // ∥
  '\u2245', // ≅
  '\u221E', // ∞
  '\u222B', // ∫
  '\u2229', // ∩
];

export const iconMapping = [
  { name: 'bold', component: BoldIcon },
  { name: 'center', component: AlignCenterIcon },
  { name: 'delete', component: BinIcon },
  { name: 'fontsizedown', component: TextSizeDecreaseIcon },
  { name: 'fontsizeup', component: TextSizeIncreaseIcon },
  { name: 'italic', component: ItalicIcon },
  { name: 'justify', component: AlignJustifyIcon },
  { name: 'left', component: AlignLeftIcon },
  { name: 'lineHeight', component: LineHeightIcon },
  { name: 'link', component: LinkIcon },
  { name: 'ol', component: OrderedListIcon },
  { name: 'right', component: AlignRightIcon },
  { name: 'strikethrough', component: StrikethroughIcon },
  { name: 'subscript', component: SubscriptIcon },
  { name: 'superscript', component: SuperscriptIcon },
  { name: 'symbols', component: SpecialCharactersIcon },
  { name: 'ul', component: UnorderedListIcon },
  { name: 'underline', component: UnderlineIcon },
  { name: 'fontcolor', component: FontColorIcon },
];
