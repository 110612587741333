import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { arrayOf, shape, string, bool, objectOf, func } from 'prop-types';
import sortBy from 'lodash/sortBy';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import Alert from '../../../../../../components/alert';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { getWhitepagesForActiveNodes } from '../../../../../../selectors/whitepages';
import Collapsible from '../../../../../../components/collapsible';
import TocNodeTitle from '../tocnode-title';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import { openWhitepageOptionsModal } from '../../../../../../actions/dialog';
import { openWhitepage, toggleWhitepageFavorite } from '../../../../../../actions/whitepages';
import { getCurrentWhitepage } from '../../../../../../selectors/player';
import FavoriteButton from '../../../buttons/FavoriteButton';
import Footer from './footer';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import AddWhitepageMenu from './AddWhitepageMenu';

export function Whitepages({ dispatch, activeNodes, whitepagesForActiveNodes, currentWhitepage }) {
  const [t] = useTranslation();
  const { showOnlyFavoriteWhitepages } = useContext(UserSettingsContext);

  const openWhitepageOptions = (tocNodeId, noteId, activeTab) => dispatch(openWhitepageOptionsModal(tocNodeId, noteId, activeTab));

  const launchWhitepage = whitepageId => dispatch(openWhitepage(whitepageId));

  const toggleFavorite = whitepage => dispatch(toggleWhitepageFavorite(whitepage));
  const sortByTitle = whitepages => sortBy(whitepages, 'title');

  const getWhitepagesForNode = useMemo(
    () => tocNodeId => {
      const whitepagesForNode = whitepagesForActiveNodes[tocNodeId];

      if (whitepagesForNode && showOnlyFavoriteWhitepages) {
        return whitepagesForNode.filter(w => w.isFavorite);
      }

      return whitepagesForNode || [];
    },
    [showOnlyFavoriteWhitepages, whitepagesForActiveNodes],
  );

  return (
    <>
      <p className="pbb-drawer__intro">{t('drawer.whitepages.title')}</p>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-whitepages">
            {activeNodes.length ? (
              <Collapsible>
                {activeNodes.map(tocNode => {
                  const whitepages = sortByTitle(getWhitepagesForNode(tocNode.id));

                  return (
                    <Collapsible.Item key={tocNode.id}>
                      <Collapsible.Title>
                        <span className="pbb-collapsible__title-text">
                          {tocNode.displayPrefix && <span className="pbb-collapsible__subtitle">{tocNode.prefix}</span>}
                          <span className="pbb-collapsible__title">
                            <TocNodeTitle tocNode={tocNode} />
                          </span>
                        </span>
                      </Collapsible.Title>
                      <Collapsible.Content>
                        <ul className="pbb-list pbb-list--borders pbb-collapsible__content">
                          {whitepages.length > 0 && <AddWhitepageMenu tocNodeId={tocNode.id} showOnlyFavoriteWhitepages={showOnlyFavoriteWhitepages} />}
                          {whitepages.map(whitepage => (
                            <li key={whitepage.id} className="pbb-list__item">
                              <div className="pbb-list__link-wrap">
                                <button
                                  type="button"
                                  className={classNames('pbb-list__link', {
                                    'pbb-list__link--active': currentWhitepage && currentWhitepage.id === whitepage.id,
                                  })}
                                  onClick={() => launchWhitepage(whitepage.id)}
                                  title={whitepage.title}
                                >
                                  <MaterialIcon materialType="whitepage" paperType={whitepage.paperType} className="pbb-list__link-icon" />
                                  <div className="pbb-list__link-text" data-testid="whitepage-title-text">
                                    {whitepage.title}
                                  </div>
                                </button>
                                <div className="pbb-list__actions">
                                  <FavoriteButton onClick={() => toggleFavorite(whitepage)} isActive={whitepage.isFavorite} className="pbb-list__action" />
                                  <DrawerOptionsMenu>
                                    <MenuItem onClick={() => openWhitepageOptions(tocNode.id, whitepage.id, 'info')}>
                                      <InfoIcon />
                                      <span>{t('tabs.info.title')}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => openWhitepageOptions(tocNode.id, whitepage.id, 'delete')}>
                                      <BinIcon />
                                      <span>{t('tabs.delete')}</span>
                                    </MenuItem>
                                  </DrawerOptionsMenu>
                                </div>
                              </div>
                            </li>
                          ))}
                          {whitepages.length === 0 && (
                            <>
                              <li>
                                <Alert
                                  message={t(showOnlyFavoriteWhitepages ? 'drawer.whitepages.no_fav_whitepages' : 'drawer.whitepages.no_whitepages')}
                                  className="pbb-alert--spaced"
                                />
                              </li>
                              <AddWhitepageMenu tocNodeId={tocNode.id} showOnlyFavoriteWhitepages={showOnlyFavoriteWhitepages} />
                            </>
                          )}
                        </ul>
                      </Collapsible.Content>
                    </Collapsible.Item>
                  );
                })}
              </Collapsible>
            ) : (
              <Alert message={t('drawer.whitepages.no_whitepages')} />
            )}
          </div>
        </div>
      </SimpleBar>
      <Footer />
    </>
  );
}

const mapStateToProps = state => ({
  activeNodes: getActiveNodes(state),
  whitepagesForActiveNodes: getWhitepagesForActiveNodes(state),
  currentWhitepage: getCurrentWhitepage(state),
});

Whitepages.propTypes = {
  dispatch: func.isRequired,
  activeNodes: arrayOf(
    shape({
      id: string,
      title: string,
      prefix: string,
      displayPrefix: bool,
    }),
  ),
  whitepagesForActiveNodes: objectOf(
    arrayOf(
      shape({
        id: string,
        moduleId: string,
        nodeId: string,
        createdAt: string,
        updatedAt: string,
      }),
    ),
  ),
  currentWhitepage: shape({
    id: string,
  }),
};

Whitepages.defaultProps = {
  activeNodes: [],
  whitepagesForActiveNodes: {},
  currentWhitepage: undefined,
};

export default connect(mapStateToProps)(Whitepages);
