import ShareIcon from '@pelckmans/business-components/icons/Share';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import SlideSetUserIcon from '@pelckmans/business-components/icons/SlideSetUser';
import { number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import OptionsMenuActions from './OptionsMenuActions';

function ReviewBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-label--alert pbb-list__label" data-testid="review-label">
      <span>{t('drawer.slideSets.states.review')}</span>
    </div>
  );
}

function ShareBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-list__label">
      <ShareIcon className="bc-icon" />
      <span>{t('drawer.slideSets.states.shared')}</span>
    </div>
  );
}

export default function SlideSetListItem({ slideSet }) {
  const { id, name, state, shared } = slideSet;

  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const handleAnalytics = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });
  };

  const hasBadges = state === 'REVIEW' || shared;

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap">
        <a
          onClick={handleAnalytics}
          className="pbb-list__link pbb-flex-wrap"
          title={name}
          href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="pbb-flex">
            {slideSet.userId ? <SlideSetUserIcon className="pbb-list__link-icon" /> : <SlideSetIcon className="pbb-list__link-icon" />}
            <span className="pbb-list__link-text">{name}</span>
          </div>
          {hasBadges && (
            <div className="pbb-list__labels">
              {state === 'REVIEW' && <ReviewBadge />}
              {shared && <ShareBadge />}
            </div>
          )}
        </a>
        <div className="pbb-list__actions">
          <DrawerOptionsMenu>
            <OptionsMenuActions slideSet={slideSet} />
          </DrawerOptionsMenu>
        </div>
      </div>
    </li>
  );
}

SlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};
