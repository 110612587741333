import axios from 'axios';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { getSaveTimeout } from '../utils';

const saveTextAnnotations = throttle(
  (url, annotations) =>
    axios.put(url, annotations, {
      headers: {
        'Content-Type': 'application/json',
        'x-amz-acl': 'bucket-owner-full-control',
      },
    }),
  getSaveTimeout(),
);

export function useWhitepageAnnotations(whitepage, initialAnnotations = []) {
  const [annotations, setAnnotations] = useState(initialAnnotations);
  const [dirty, setDirty] = useState(false);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);

  const saveAnnotations = useCallback(() => {
    if (dirty) {
      saveTextAnnotations(whitepage.annotationsUrl, annotations).then(() => setDirty(false));
    }
  }, [annotations, whitepage, dirty]);

  useEffect(() => () => saveTextAnnotations.flush(), [whitepage]);

  useEffect(() => {
    saveAnnotations();
  }, [saveAnnotations]);

  const addAnnotation = useCallback(annotation => {
    unstable_batchedUpdates(() => {
      setSelectedAnnotationId(annotation.id);
      setAnnotations(prev => [...prev, annotation]);
      setDirty(true);
    });
  }, []);

  const editAnnotation = useCallback(annotation => {
    setAnnotations(prev =>
      prev.map(a => {
        if (a.id === annotation.id) {
          return { ...a, ...annotation };
        }

        return a;
      }),
    );
    setDirty(true);
  }, []);

  const removeAnnotation = useCallback(
    annotationId => {
      if (selectedAnnotationId === annotationId) setSelectedAnnotationId(null);
      setAnnotations(prev => prev.filter(x => x.id !== annotationId));
    },
    [selectedAnnotationId],
  );

  const setSelectedAnnotation = useCallback(annId => {
    setSelectedAnnotationId(annId);
    if (annId) setAnnotations(prev => [...prev.filter(x => x.id !== annId), prev.find(x => x.id === annId)]);
  }, []);

  const removeBatch = useCallback(ids => {
    setAnnotations(prev => prev.filter(x => !ids.includes(x.id)));
  }, []);

  useEffect(() => {
    function removeVisibleAnnotations() {
      if (annotations.length > 0) removeBatch(annotations.map(annotation => annotation.id));
    }

    document.addEventListener('erase-all-clicked', removeVisibleAnnotations);

    return () => {
      document.removeEventListener('erase-all-clicked', removeVisibleAnnotations);
    };
  }, [removeBatch, annotations]);

  const selectedAnnotation = annotations.find(x => x.id === selectedAnnotationId);

  return {
    annotations,
    addAnnotation,
    editAnnotation,
    selectedAnnotation,
    setSelectedAnnotation,
    removeAnnotation,
  };
}
