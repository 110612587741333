import React from 'react';
import { flushSync, render } from 'react-dom';
import { Jodit } from 'jodit';

import BoldIcon from '@pelckmans/business-components/icons/TextEditorBold';
import UnderlineIcon from '@pelckmans/business-components/icons/TextEditorUnderline';
import ItalicIcon from '@pelckmans/business-components/icons/TextEditorItalic';
import AlignCenterIcon from '@pelckmans/business-components/icons/TextEditorAlignCenter';
import AlignLeftIcon from '@pelckmans/business-components/icons/TextEditorAlignLeft';
import AlignRightIcon from '@pelckmans/business-components/icons/TextEditorAlignRight';
import AlignJustifyIcon from '@pelckmans/business-components/icons/TextEditorAlignJustify';
import LineHeightIcon from '@pelckmans/business-components/icons/TextEditorLineHeight';
import LinkIcon from '@pelckmans/business-components/icons/TextEditorLink';
import OrderedListIcon from '@pelckmans/business-components/icons/TextEditorOrderedList';
import SpecialCharactersIcon from '@pelckmans/business-components/icons/TextEditorSpecialCharacters';
import StrikethroughIcon from '@pelckmans/business-components/icons/TextEditorStrikethrough';
import SubscriptIcon from '@pelckmans/business-components/icons/TextEditorSubscript';
import SuperscriptIcon from '@pelckmans/business-components/icons/TextEditorSuperscript';
import TextSizeIncreaseIcon from '@pelckmans/business-components/icons/TextEditorTextSizeIncrease';
import TextSizeDecreaseIcon from '@pelckmans/business-components/icons/TextEditorTextSizeDecrease';
import UnorderedListIcon from '@pelckmans/business-components/icons/TextEditorUnorderedList';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import FontColorIcon from '@pelckmans/business-components/icons/TextEditorFontColor';

function renderToString(elem) {
  const div = document.createElement('div');

  flushSync(() => {
    render(elem, div);
  });

  return div.innerHTML;
}

export function updateFontSizeIcon(editor) {
  editor.toolbar.buttons
    .find(x => x.name === 'fontsize')
    .state.on('change.value', (property, prevValue, nextValue) => {
      const fontSizeIconWrapper = document.querySelector('.jodit-toolbar-button__button .jodit-icon_fontsize').parentElement;

      fontSizeIconWrapper.innerHTML = `<svg class="jodit-icon jodit-icon_fontsize" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" ><text x="50%" y="50%" lengthAdjust="spacing" font-size="1.5em" text-anchor="middle" dy=".3em" font-weight="bold">${nextValue}</text></svg>`;
    });
}

export default function initJodit() {
  Jodit.modules.Icon.set('bold', renderToString(<BoldIcon />));
  Jodit.modules.Icon.set('underline', renderToString(<UnderlineIcon />));
  Jodit.modules.Icon.set('italic', renderToString(<ItalicIcon />));
  Jodit.modules.Icon.set('fontsize', '<div class="jodit-icon jodit-icon_fontsize"></div>');
  Jodit.modules.Icon.set('center', renderToString(<AlignCenterIcon />));
  Jodit.modules.Icon.set('left', renderToString(<AlignLeftIcon />));
  Jodit.modules.Icon.set('right', renderToString(<AlignRightIcon />));
  Jodit.modules.Icon.set('justify', renderToString(<AlignJustifyIcon />));
  Jodit.modules.Icon.set('lineHeight', renderToString(<LineHeightIcon />));
  Jodit.modules.Icon.set('link', renderToString(<LinkIcon />));
  Jodit.modules.Icon.set('ol', renderToString(<OrderedListIcon />));
  Jodit.modules.Icon.set('symbols', renderToString(<SpecialCharactersIcon />));
  Jodit.modules.Icon.set('strikethrough', renderToString(<StrikethroughIcon />));
  Jodit.modules.Icon.set('subscript', renderToString(<SubscriptIcon />));
  Jodit.modules.Icon.set('superscript', renderToString(<SuperscriptIcon />));
  Jodit.modules.Icon.set('fontsizedown', renderToString(<TextSizeDecreaseIcon />));
  Jodit.modules.Icon.set('fontsizeup', renderToString(<TextSizeIncreaseIcon />));
  Jodit.modules.Icon.set('ul', renderToString(<UnorderedListIcon />));
  Jodit.modules.Icon.set('delete', renderToString(<BinIcon />));
  Jodit.modules.Icon.set('fontcolor', renderToString(<FontColorIcon />));
}
