import React, { useCallback, useState } from 'react';
import { Dialog, ModalDialog } from '@pelckmans/business-components/components/dialog';
import AnnotationSetIcon from '@pelckmans/business-components/icons/AnnotationSet';
import PencilWithSquareIcon from '@pelckmans/business-components/icons/PencilWithSquare';
import SlideDuplicateIcon from '@pelckmans/business-components/icons/SlideDuplicate';
import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import { useTranslation } from 'react-i18next';
import { Button } from '@pelckmans/business-components/components/button';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';

import { closeDialog } from '../../../../../actions/dialog';
import dialogTypes from '../../../../../enums/dialogTypes';
import { createAnnotationSet, setActiveAnnotationSet, deleteAnnotationSet, copyAnnotationSet } from '../../../../../actions/annotationSets';
import { getActiveAnnotationSet, getAnnotationSets, getCreateAnnotationSetLoading } from '../../../../../selectors/annotationSets';
import EditAnnotationSetNameField from './editAnnotationSetNameField';
import Confirmation from '../confirmation';

const ANNOTATION_SETS_LIMIT = 100;

export default function AnnotationSetsManagementModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);
  const [annotationSetInEditMode, setAnnotationSetInEditMode] = useState(null);
  const createAnnotationSetLoading = useSelector(getCreateAnnotationSetLoading);
  const [deleteModalAnnotationSetId, setDeleteModalAnnotationSetId] = useState(null);

  const hasMaxLimitReached = annotationSets.length === ANNOTATION_SETS_LIMIT;

  const handleClose = useCallback(() => dispatch(closeDialog(undefined, dialogTypes.ANNOTATION_SETS_MANAGEMENT)), [dispatch]);

  function addNewAnnotationSet() {
    dispatch(createAnnotationSet(t('annotationSets.templateDefaultName')));
  }

  function onItemClick(annotationSet) {
    dispatch(setActiveAnnotationSet(annotationSet));
  }

  function handleDelete(annotationSetId) {
    dispatch(deleteAnnotationSet(annotationSetId, t('annotationSets.templateDefaultName')));
  }

  function handleCopy(annotationSetId) {
    dispatch(copyAnnotationSet(annotationSetId, t('annotationSets.templateDefaultName')));
  }

  return (
    <>
      <ModalDialog id="annotation-sets-modal" onClose={handleClose} fullScreen>
        <Dialog.Header
          title={
            <>
              <AnnotationSetIcon className="header-icon" />
              {t('annotationSets.managementModal.title')}
            </>
          }
        />
        <Dialog.Body>
          <SimpleBar className="pbb-drawer__contentwrap">
            <div className="pbb-drawer__content">
              <div className="pbb-annotation-sets">
                {annotationSets.map(annotationSet => (
                  <div className="annotation-set" key={annotationSet.id} data-testid="annotation-set">
                    {annotationSetInEditMode === annotationSet.id ? (
                      <EditAnnotationSetNameField annotationSet={annotationSet} setAnnotationSetInEditMode={setAnnotationSetInEditMode} />
                    ) : (
                      <>
                        <button
                          type="button"
                          className={classNames('annotation-set__title', { 'annotation-set__title--active': annotationSet.id === activeAnnotationSet.id })}
                          onClick={() => onItemClick(annotationSet)}
                          title={annotationSet.name}
                        >
                          {annotationSet.name}
                        </button>
                        <button
                          type="button"
                          aria-label={t('annotationSets.managementModal.toolTip.edit', { name: annotationSet.name })}
                          className="annotation-set__button"
                          title={t('annotationSets.managementModal.toolTip.edit', { name: annotationSet.name })}
                          onClick={() => setAnnotationSetInEditMode(annotationSet.id)}
                        >
                          <PencilWithSquareIcon className="action-icon" />
                        </button>
                        <button
                          type="button"
                          aria-label={t('annotationSets.managementModal.toolTip.duplicate', { name: annotationSet.name })}
                          className="annotation-set__button"
                          title={t('annotationSets.managementModal.toolTip.duplicate', { name: annotationSet.name })}
                          onClick={() => handleCopy(annotationSet.id)}
                        >
                          <SlideDuplicateIcon className="action-icon" />
                        </button>
                        <button
                          type="button"
                          aria-label={t('annotationSets.managementModal.toolTip.delete', { name: annotationSet.name })}
                          className="annotation-set__button"
                          data-testid="delete-button"
                          title={
                            annotationSets.length === 1
                              ? t('annotationSets.managementModal.toolTip.deleteDisabled')
                              : t('annotationSets.managementModal.toolTip.delete', { name: annotationSet.name })
                          }
                          onClick={() => setDeleteModalAnnotationSetId(annotationSet.id)}
                          disabled={annotationSets.length === 1}
                        >
                          <BinIcon className="action-icon" />
                        </button>
                      </>
                    )}
                    {deleteModalAnnotationSetId === annotationSet.id && (
                      <Confirmation
                        title={`${t('actions.delete')} ${annotationSet.name}`}
                        icon={<BinIcon />}
                        message={t('annotationSets.managementModal.deleteMessage')}
                        confirmationText={t('actions.delete')}
                        confirmationClassName="pbb-btn--danger"
                        cancellationText={t('confirmation.cancel')}
                        onConfirm={() => handleDelete(annotationSet.id)}
                        onCancel={() => setDeleteModalAnnotationSetId(null)}
                      />
                    )}
                  </div>
                ))}
                <Button
                  size="small"
                  className="add-button"
                  onClick={addNewAnnotationSet}
                  disabled={createAnnotationSetLoading || hasMaxLimitReached}
                  title={hasMaxLimitReached ? t('annotationSets.managementModal.toolTip.limitReached') : t('annotationSets.managementModal.add')}
                >
                  <PlusInCircleIcon className="add-icon" />
                  {t('annotationSets.managementModal.add')}
                </Button>
              </div>
            </div>
          </SimpleBar>
        </Dialog.Body>
      </ModalDialog>
    </>
  );
}
