import React, { useCallback, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FabricService from '../../../services/fabric-service';
import { getCurrentSpread, getMarkingsForCurrentPages, getVisiblePages } from '../../../../../selectors/rendering';
import position from '../../../../../enums/position';
import { getTotalPagesForDigibook } from '../../../../../selectors/digibooks';
import { createMarking } from '../../../../../actions/tools';
import { UserSettingsContext } from '../../../context/user-settings-context';
import ToolsEnum from '../../../../../enums/tools';
import { getCurrentTool, getTools } from '../../../../../selectors/tools';
import MathTools from '../math-tools/MathTools';

export function MarkingLayer({ bookPages, isLoading, pagesRendered, viewportTransform }) {
  const canvasRef = useRef();
  const fabricService = useRef();
  const containerRef = useRef();
  const visiblePages = useSelector(getVisiblePages);
  const totalPages = useSelector(getTotalPagesForDigibook);
  const spread = useSelector(getCurrentSpread);
  const dispatch = useDispatch();
  const tools = useSelector(getTools);
  const currentTool = useSelector(getCurrentTool);
  const markings = useSelector(getMarkingsForCurrentPages);
  const { showMarkings } = useContext(UserSettingsContext);

  useEffect(() => {
    const {
      current: { clientWidth, clientHeight },
    } = containerRef;

    fabricService.current = new FabricService(canvasRef.current.id);
    fabricService.current.initialize(clientHeight, clientWidth);

    return () => {
      fabricService.current.dispose();
    };
  }, []);

  const isSinglePage = visiblePages?.filter(x => x !== null).length === 1;
  const isRightPage = visiblePages && visiblePages[0] === null && typeof visiblePages[1] === 'number';

  const onShapeDrawn = useCallback(
    drawnShape => {
      const shapeToAdd = fabricService.current.offsetDrawings(drawnShape, isSinglePage, isRightPage);

      const clonedShape = shapeToAdd.toObject();
      clonedShape.meta = shapeToAdd.meta;

      dispatch(createMarking(clonedShape, spread));
    },
    [dispatch, spread, isSinglePage, isRightPage],
  );

  useEffect(() => {
    // Renders bookPages invisible on the canvas and sets proper position, these are used in our brushes to determine the paintable dimensions.
    if (bookPages && visiblePages && !isLoading) {
      fabricService.current.clearBookPages();

      const firstPageIndex = visiblePages?.findIndex(pageNumber => pageNumber !== 0);
      const getPositionOnSpread = pageNumber => {
        const standAloneLeftPage =
          pageNumber === 0 || // cover
          pageNumber === 1 || // first page
          pageNumber === totalPages + 1; // backcover

        if (pageNumber % 2 === 0 && !standAloneLeftPage) return 'right';

        return 'left';
      };

      bookPages.forEach((page, i) => {
        fabricService.current.renderBookPage(page, i === 0 ? position.LEFT : position.RIGHT, getPositionOnSpread(firstPageIndex + i));
      });

      fabricService.current.setBookPagesInvisible();
    }
  }, [bookPages, visiblePages, totalPages, isLoading]);

  useEffect(() => {
    fabricService.current.addFreeDrawingListeners(onShapeDrawn);

    return () => {
      fabricService.current.removeFreeDrawingListeners();
    };
  }, [onShapeDrawn]);

  useEffect(() => {
    if (showMarkings) fabricService.current.showMarkings(markings, isSinglePage, isRightPage);
    else fabricService.current.clearMarkings();
  }, [isRightPage, isSinglePage, markings, showMarkings]);

  useEffect(() => {
    fabricService.current.setCurrentTool(currentTool, { ...tools[currentTool] });
  }, [tools, currentTool]);

  useEffect(() => {
    const vptHandler = e => {
      fabricService.current.fabricCanvas.set('viewportTransform', e.detail);
    };

    document.addEventListener('canvas-panned', vptHandler);

    return () => {
      document.removeEventListener('canvas-panned', vptHandler);
    };
  }, []);

  useEffect(() => {
    if (visiblePages) fabricService.current.setAmountOfVisiblePages(visiblePages.filter(x => x !== null).length);
  }, [visiblePages]);

  useEffect(() => {
    const onResize = () => {
      fabricService.current.setCanvasDimensions(containerRef.current.clientHeight, containerRef.current.clientWidth);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isLoading]);

  useEffect(() => {
    fabricService.current.renderAll();
  });

  const saveArc = circlePosition => {
    const circle = fabricService.current.getTempCircleToPersist();
    if (!circle) return;

    const pos = { ...circlePosition };

    if (isRightPage) {
      pos.left += bookPages[0]?.width;
    }

    circle.set(pos);

    dispatch(createMarking(circle, spread));
  };

  const updateFreeDrawingStrategy = useCallback(strategy => {
    fabricService.current.fabricCanvas.freeDrawingBrush.freeDrawingStrategy = strategy;
  }, []);

  return (
    <>
      <div
        ref={containerRef}
        className={classNames('pbb-drawing-layer', {
          'pbb-drawing-layer--loading': isLoading,
          'pbb-drawing-layer--interactive': [ToolsEnum.PENCIL, ToolsEnum.MARKER, ToolsEnum.CLASSIC_ERASER].includes(currentTool),
        })}
      >
        <canvas ref={canvasRef} id="drawing-layer" />
      </div>
      <MathTools
        bookDimensions={{ width: bookPages[0]?.width || 0, height: bookPages[0]?.height || 0 }}
        pagesRendered={pagesRendered}
        viewPortTransform={viewportTransform}
        onDraftingCompassTempDraw={(...args) => fabricService.current.renderTempCircle(...args)}
        onDraftingCompassFinishedDrawing={saveArc}
        setFreeDrawingStrategy={updateFreeDrawingStrategy}
      />
    </>
  );
}

MarkingLayer.propTypes = {
  bookPages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  pagesRendered: PropTypes.array.isRequired,
  viewportTransform: PropTypes.array.isRequired,
};

MarkingLayer.defaultProps = {
  isLoading: false,
};
