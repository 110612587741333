import React from 'react';
import classNames from 'classnames';
import { func, arrayOf, bool, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import LightBulbIcon from '@pelckmans/business-components/icons/LightBulb';
import PrintIcon from '@pelckmans/business-components/icons/Print';
import { openTocNodeOptionsModal } from '../../../../../../actions/dialog';
import DrawerOptionsMenu from '../DrawerOptionsMenu';

const TocItem = ({ onNodeClicked, node, expanded, active, subnodes, level = 1, showOptions, hasSharedSolutions }) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const openOptionsModal = activeTab => dispatch(openTocNodeOptionsModal(node, activeTab));

  return (
    <li
      className={classNames(`pbb-toc__item--level-${level}`, 'pbb-toc__item', {
        'pbb-expanded': expanded && level === 1,
        'pbb-toc__item--sub': level > 1,
        'pbb-toc__item--active': active,
      })}
    >
      <span className="pbb-toc__item-wrapper">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="pbb-toc__item-link"
          href="#"
          onClick={e => {
            e.preventDefault();
            onNodeClicked(node);
          }}
        >
          <span className="pbb-toc__item-bookmark" data-testid="bookmark-icon">
            <i className="icon-bb-bookmark" />
          </span>

          <div className="pbb-toc__item-title-wrapper">
            {node.displayPrefix && (
              <div className="pbb-toc__item-subtitle" data-testid="node-prefix">
                {node.prefix}
              </div>
            )}
            <div className="pbb-toc__item-title">{node.title}</div>
          </div>
        </a>
        {hasSharedSolutions &&
          (showOptions ? (
            <button
              type="button"
              className="pbb-toc__option pbb-shared-solution-indicator"
              onClick={() => openOptionsModal('sharing_solutions')}
              title={t('sharing.solutions.tocIndicator.tooltip')}
              disabled={!hasSharedSolutions}
            >
              <LightBulbIcon />
            </button>
          ) : (
            <div className="pbb-toc__option pbb-shared-solution-indicator" title={t('sharing.solutions.tocIndicator.tooltip')}>
              <LightBulbIcon />
            </div>
          ))}
        {showOptions && (
          <DrawerOptionsMenu>
            <MenuItem onClick={() => openOptionsModal('sharing_solutions')}>
              <LightBulbIcon />
              <span>{t('tabs.sharing_solutions')}</span>
            </MenuItem>
            <MenuItem onClick={() => openOptionsModal('print')}>
              <PrintIcon />
              <span>{t('tabs.print')}</span>
            </MenuItem>
          </DrawerOptionsMenu>
        )}
      </span>
      {subnodes && expanded && <ul className={`pbb-toc__list-level-${level + 1}`}>{subnodes}</ul>}
    </li>
  );
};

TocItem.propTypes = {
  onNodeClicked: func.isRequired,
  node: shape({ id: string, displayPrefix: bool, prefix: string, title: string }),
  expanded: bool,
  active: bool,
  subnodes: arrayOf(shape({ displayPrefix: bool, prefix: string, title: string })),
  level: number,
  showOptions: bool,
  hasSharedSolutions: bool,
};

TocItem.defaultProps = {
  node: { id: '', displayPrefix: false, prefix: '', title: '' },
  expanded: false,
  active: false,
  subnodes: [],
  level: 1,
  showOptions: false,
  hasSharedSolutions: false,
};

export default TocItem;
