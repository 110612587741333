import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import Colors from '../../../../../enums/colors';
import tools from '../../../../../enums/tools';

const buildColorClassName = tool => color => `pbb-${tool}-color pbb-${tool}-color--${color}`;

const ColorPicker = ({ onColorClick, variant, selectedColor }) => {
  const [activeColor, setActiveColor] = React.useState(selectedColor);
  const getColorClassName = buildColorClassName(variant);
  const { t } = useTranslation();
  return (
    <div className={classNames('pbb-menu__colors', `pbb-menu__colors--${variant}`)}>
      {Object.values(Colors).map(color => (
        <button
          onClick={() => {
            setActiveColor(color);
            onColorClick(color);
          }}
          key={color}
          type="button"
          className={classNames(getColorClassName(color), 'color-picker__button', { 'color-picker__button--active': activeColor === color })}
          title={t(`colors.${color}`)}
        >
          {variant === tools.ANNOTATION ? <i role="img" className="color-picker__icon icon-bb-annotation" /> : <span className="indicator" />}
        </button>
      ))}
    </div>
  );
};

ColorPicker.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  selectedColor: string.isRequired,
};

export default ColorPicker;
