import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { oneOf, string, shape, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Material from './material';
import Toc from './toc';
import Notes from './notes';

import DrawerMode from '../../../../../enums/drawermode';
import Whitepages from './whitepages';
import { getOpenMaterialAssignment } from '../../../../../selectors/dialogs';
import { isTeacherAlike } from '../../../../../selectors/user';
import SlideSets from './slideSets';
import { retrievePublishedSlideSetsForCurrentPages } from '../../../../../actions/slideSets';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function Drawer({ isOpen, activeDrawer, digibook, teacherFeaturesEnabled, superModuleId }) {
  const isMaterialAssignmentModalOpen = useSelector(getOpenMaterialAssignment);
  const isTeacher = useSelector(isTeacherAlike);
  const dispatch = useDispatch();

  const prevIsOpen = usePrevious(isOpen);

  const slidein = !isOpen && prevIsOpen;
  const slideout = isOpen && !prevIsOpen;

  useEffect(() => {
    if (!isOpen || activeDrawer !== DrawerMode.SLIDE_SETS || !isTeacher) return () => {};

    const handleFocus = () => {
      if (document.visibilityState === 'visible') {
        dispatch(retrievePublishedSlideSetsForCurrentPages());
      }
    };

    window.addEventListener('visibilitychange', handleFocus);

    return () => window.removeEventListener('visibilitychange', handleFocus);
  }, [activeDrawer, dispatch, isOpen, isTeacher]);

  return (
    <div className={classNames('pbb-drawer', { 'pbb-drawer--material-assignment-modal-active': isMaterialAssignmentModalOpen }, { slideout, slidein, 'pbb-active': isOpen })}>
      {activeDrawer === DrawerMode.TOC && <Toc superModuleId={superModuleId} digibook={digibook} />}
      {activeDrawer === DrawerMode.MATERIAL && <Material />}
      {activeDrawer === DrawerMode.NOTES && <Notes />}
      {activeDrawer === DrawerMode.SLIDE_SETS && <SlideSets />}
      {teacherFeaturesEnabled && activeDrawer === DrawerMode.WHITEPAGES && <Whitepages />}
    </div>
  );
}

Drawer.propTypes = {
  activeDrawer: oneOf(Object.values(DrawerMode)),
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
  }).isRequired,
  isOpen: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  teacherFeaturesEnabled: bool,
};

Drawer.defaultProps = {
  activeDrawer: DrawerMode.TOC,
  superModuleId: undefined,
  teacherFeaturesEnabled: false,
};

export default Drawer;
